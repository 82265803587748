import React from "react";
import { motion } from "framer-motion";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import "./DemoSection.css"; // Import the CSS file
import { Link } from "react-router-dom";
import LogoBlue from "../../../assets/Logo-Blue.png";

const YOUTUBE_VIDEO_URL =
  "https://www.youtube.com/embed/oF5ZjX-oRlk?si=5W5fFoid6p4e1FEI";

const DemoSection = () => {
  return (
    <section className="demo-section-container">
      <h2 className="demo-section-title">Course Creation Demo</h2>
      <div className="demo-content-wrapper">
        <div className="demo-selling-text">
          <h2>Check Out How Easy It Is To Create A Course</h2>
          {/* <h1 className="demo-heading">inspirEDU</h1> */}
          <img src={LogoBlue} alt="logo" height={70} />
          <div className="demo-callToAction">
            <div className="revolutionize-hero-actions">
              <motion.button
                type="button"
                className="btn btn-lg rounded-pill btn-success d-flex align-items-center gap-2 large-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
                onClick={() => {}} // Example onClick handler
                style={{ backgroundColor: "#00274D" }} // Set a fixed width
              >
                <Link
                  to={"/interactive-demo"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Click here to experience an interactive demo
                </Link>
              </motion.button>

              {/* Add "Or" text between the buttons */}
              <span
                className="or-text"
                style={{ margin: "0 15px", fontWeight: "bold" }}
              >
                OR
              </span>

              <motion.button
                type="button"
                className="btn btn-lg rounded-pill btn-success d-flex align-items-center gap-2 large-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
                onClick={() => {
                  document
                    .getElementById("demo-form")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Click here to schedule your personalized demo
              </motion.button>
            </div>
          </div>
        </div>

        {/* Video Container */}
        <div className="demo-video-container">
          <iframe
            src={YOUTUBE_VIDEO_URL}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="System Demo Video"
            className="demo-video"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default DemoSection;
