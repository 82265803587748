import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const LFooter = () => {
  return (
    <footer className="my-footer">
      <div className="footer-content">
        <ul className="footer-links">
          <h2 className="links-section-heading">Quick Links</h2>
          {/* <li>
            <a href="#choose-us-section" className={"brtt-link"}>
              Why Choose Us
            </a>
          </li>
          <li>
            <a href="#key-features-section" className={"brtt-link"}>
              Key Features
            </a>
          </li>
          <li>
            <a href="#testimonials-section" className={"brtt-link"}>
              Testimonials
            </a>
          </li>
          <li>
            <a href="#contact-section" className={"brtt-link"}>
              Contact Us
            </a>
          </li> */}
          <li>
            <a
              href="https://portal.inspir-edu.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Start Trial
            </a>
          </li>
          <Link to="/policy" className={"brtt-link"}>
            Policy
          </Link>
          <Link to="/terms-and-conditions" className={"brtt-link"}>
            Terms
          </Link>
          <li>
            <a href="#faq-section" className={"brtt-link"}>
              FAQ
            </a>
          </li>
          <li>
            <a
              href="https://portal.inspir-edu.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={"brtt-link"}
            >
              Sign in
            </a>
          </li>
        </ul>
        <p className="copyright">&copy; 2024 inspirEDU. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default LFooter;
