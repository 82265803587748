import React, { useEffect, useRef, useState } from "react";
import "./landing-screen.css";
import HeroSection from "../components/landing-page/hero-section";
import Segmentations from "../components/landing-page/segmentations";
import Contact from "../components/landing-page/contact";
import FAQ from "../components/landing-page/faq";
import LFooter from "../components/landing-page/footer";
import Plans from "../components/landing-page/plans";
import DemoSection from "../components/landing-page/demo-section/DemoSection";
import GetDemo from "../components/GetDemo/GetDemo";
import InteractiveDemo from "../components/landing-page/demo-section/InteractiveDemo";
import ProductHunt from "../components/landing-page/product-hunt/ProductHunt";
import Pricing from "../components/Pricing/Pricing";
import { Container } from "react-bootstrap";

const ADMIN_BASE_URL = "https://portal.inspir-edu.com/";

const LandingPage = () => {
  const handleSubscribeClick = (e) => {
    e.stopPropagation();
    window.open(ADMIN_BASE_URL, "_blank");
  };
  return (
    <>
      <div className="content">
        <HeroSection onClick={handleSubscribeClick} />
        {/* <InteractiveDemo /> */}
        <Segmentations onClick={handleSubscribeClick} />
        <GetDemo />
        {/* <Plans /> */}
        <Pricing />
        <Container className="teachers-page py-5">
          <DemoSection onClick={handleSubscribeClick} />
        </Container>
        {/* Product Hunt Section */}
        <ProductHunt />
        <FAQ />
        <Contact onClick={handleSubscribeClick} />
        <LFooter />
      </div>
    </>
  );
};

export default LandingPage;
