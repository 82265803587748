import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import shape21 from "../../assets/shape/shape-21.png";
import shape22 from "../../assets/shape/shape-22.png";
import "./Pricing.css";
import { generalPackages } from "../../data/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faChalkboardTeacher,
  faClock,
  faGraduationCap,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useState } from "react";

const ADMIN_BASE_URL = "https://portal.inspir-edu.com/";

const Pricing = () => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [animate, setAnimate] = useState(false);
  const pricingRef = React.useRef(null);

  const checkDiscountVisibility = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setShowDiscount(true);
        setAnimate(true);

        // Remove the animation class after 1 second to allow re-triggering
        setTimeout(() => {
          setAnimate(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(checkDiscountVisibility, {
      threshold: 0.1, // Lowered threshold for better mobile sensitivity
    });

    if (pricingRef.current) {
      observer.observe(pricingRef.current);
    }

    const handleResize = () => {
      // Recheck on mobile devices when viewport is resized
      if (window.innerWidth < 768) {
        observer.disconnect();
        observer.observe(pricingRef.current);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef.current);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubscribeClick = () => {
    window.open(ADMIN_BASE_URL, "_blank");
  };

  const calculateDiscountedPrice = (price) => {
    return (price * 0.5).toFixed(2);
  };

  return (
    <>
      <div
        id="pricing"
        ref={pricingRef}
        className="agency-pricing-area bg-color-f9f5f4 pt-100 pb-70 overflow-hidden"
      >
        <div className="container">
          <div className="agency-section-title">
            <h2>Choose the best plan for you</h2>
          </div>

          <div className="agency-pricing-tabs">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Tabs>
                  {/* <TabList>
                    <Tab>Monthly</Tab>
                  </TabList> */}

                  <TabPanel>
                    <div
                      className="row justify-content-center"
                      style={{ marginTop: "2rem" }}
                    >
                      {/* Basic Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="200"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <h1
                              className={animate ? "splash-animation" : ""}
                              style={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ${generalPackages.basicPackage.price}
                              </p>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  marginTop: "0.25rem",
                                }}
                              >
                                /month
                              </span>
                            </h1>
                            <h6>{generalPackages.basicPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {generalPackages.basicPackage.suitableForBusiness}
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.basicPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.basicPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.basicPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Advanced Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="400"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <h1
                              className={animate ? "splash-animation" : ""}
                              style={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ${generalPackages.advancedPackage.price}
                              </p>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  marginTop: "0.25rem",
                                }}
                              >
                                /month
                              </span>
                            </h1>

                            <h6>{generalPackages.advancedPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.advancedPackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.advancedPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.advancedPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.advancedPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Premium Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box active">
                          <div className="pricing-content">
                            <h1
                              className={animate ? "splash-animation" : ""}
                              style={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ${generalPackages.premiumPackage.price}
                              </p>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  marginTop: "0.25rem",
                                }}
                              >
                                /month
                              </span>
                            </h1>
                            <h6>{generalPackages.premiumPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.premiumPackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.premiumPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.premiumPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.premiumPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                            <div className="popular">MOST POPULAR</div>
                          </div>
                        </div>
                      </div>

                      {/* Enterprise Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="800"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <h1
                              className={animate ? "splash-animation" : ""}
                              style={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ${generalPackages.enterprisePackage.price}
                              </p>
                              <span
                                style={{
                                  fontSize: "1rem",
                                  marginTop: "0.25rem",
                                }}
                              >
                                /month
                              </span>
                            </h1>
                            <h6>{generalPackages.enterprisePackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.enterprisePackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.enterprisePackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.enterprisePackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.enterprisePackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>

          <img src={shape21} className="pricing-shape-1" alt="Image" />
          <img src={shape22} className="pricing-shape-2" alt="Image" />
        </div>
      </div>
    </>
  );
};

export default Pricing;
